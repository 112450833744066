import { lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Language from '@mui/icons-material/Language';
import Home from '@mui/icons-material/Home';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import LocalPrintshop from '@mui/icons-material/LocalPrintshop';
import BarChart from '@mui/icons-material/BarChart';
import Send from '@mui/icons-material/Send';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Person from '@mui/icons-material/Person';
import Store from '@mui/icons-material/Store';
import People from '@mui/icons-material/People';
import FactCheck from '@mui/icons-material/FactCheck';
import { Domain } from 'mosaic-react-icons';
import auth from 'helpers/authenticationHelper';
import { set as setCookie } from 'infrastructure/cookieHelper';
import ProtectedRoute from 'auth/ProtectedRoute';
import AuthRoute from './AuthRouteConnector';
import App from '../app/AppConnector';
import LoginOidc from '../pages/loginOtp/LoginOidc';
import LoginOtpConnector from 'pages/loginOtp/LoginOtpConnector';
import LogoutSSO from 'pages/logoutSSO/LogoutSSO';
import PostLogin from '../pages/loginOtp/PostLoginConnector';
import EulaPage from 'pages/eula/EulaPage';

import {
  TIR_R_RECORD_PAYER,
  TIR_R_RECORD_PAYEE,
  TIR_R_RECORD_NON_WITHHOLDING,
  TIR_R_RECORD_WITHHOLDING,
  TIR_FM_ENTERDIR_PRINTFILES,
  TIR_FM_ENTERDIR_UPLOADED,
  TIR_FM_ENTERDIR_TIMIMAGES,
  TIR_C_REPORT,
  TIR_C_RECORD_PAYER,
  TIR_U_RECORD_PAYER,
  TIR_C_RECORD_PAYEE,
  TIR_U_RECORD_PAYEE,
  TIR_U_RECORD_NON_WITHHOLDING,
  TIR_USER,
  TIR_DEVELOPER,
  TIR_ENT,
  TIR_CORP,
  S1_C_USER,
  S1_U_USER,
  S1_D_USER,
  S1_RESET_USER,
  TIR_MANAGE_TAX_YEARS,
  TIR_RTT_USER,
  TIR_C_ID_VERIFY,
  TIR_R_VERIFY_IDENTITY,
  TIR_C_SOLICITATION,
  TIR_R_BULK_TIN,
  TIR_C_BULK_TIN,
  TIR_C_USER,
  TIR_U_USER,
  TIR_D_USER,
  TIR_RESET_USER
} from 'helpers/permissionConstants';

import {
  TAX_IDENTITY_MANAGEMENT_SOLICITATION,
  TAX_IDENTITY_MANAGEMENT_RESPONSE,
  MANAGED_SERVICES,
  BULK_TIN,
  REAL_TIME_TIN,
  WITHHOLDING_FORMS
} from 'helpers/configurationConstants';

import { productTypeCode } from 'helpers/enums';

import Overview from 'pages/overview/OverviewPage'; // TODO: problem to create chunk file in build step

//const Overview = lazy(() => import(/* webpackChunkName: "OverviewPage" */ '../pages/overview/OverviewPage'));
const HomePage = lazy(() => import(/* webpackChunkName: "HomePage" */ '../pages/corporate/homePage/HomePage'));
const PayersPage = lazy(() => import(/* webpackChunkName: "PayersPage" */ '../pages/payersPage/PayersPage'));
const RecipientsPage = lazy(() => import(/* webpackChunkName: "RecipientsPage" */ '../pages/recipientsPage/RecipientsPage'));
const FormsPage = lazy(() => import(/* webpackChunkName: "FormsPage" */ '../pages/formsPage/FormsPage'));
const DataEntRoot = lazy(() => import(/* webpackChunkName: "DataPage" */ '../pages/dataEntRoot/DataEntRoot'));
const IdentityManagementRoot = lazy(() => import(/* webpackChunkName: "IdentityManagementRoot" */ '../pages/identityManagement/IdentityManagementRoot'));
const TaxIdentity = lazy(() => import(/* webpackChunkName: "TaxIdentityPage" */ '../pages/taxIdentity/TaxIdentity'));
const BulkTin = lazy(() => import(/* webpackChunkName: "BulkTinPage" */ '../pages/taxIdentity/pages/bulkTin/BulkTin'));
const ImportHistory = lazy(() => import(/* webpackChunkName: "ImportHistoryPage" */ '../pages/importHistory/ImportHistoryRoot'));
const ImportResults = lazy(() => import(/* webpackChunkName: "ImportResultsPage" */ '../pages/corporate/import/importResults/ImportResults'));
const PrintCorpRoot = lazy(() => import(/* webpackChunkName: "PrintPage" */ '../pages/printCorp/PrintCorpRoot'));
const PrintENTRoot = lazy(() => import(/* webpackChunkName: "PrintEntPage" */ '../pages/printENT/PrintENTRoot'));
const PrintHistoryRoot = lazy(() => import(/* webpackChunkName: "PrintHistoryPage" */ '../pages/printHistory/PrintHistoryRoot'));
const TransmitCorpRoot = lazy(() => import(/* webpackChunkName: "TransmitPage" */ '../pages/transmitCorp/TransmitCorpRoot'));
const TransmitENTRoot = lazy(() => import(/* webpackChunkName: "TransmitEntPage" */ '../pages/transmitENT/TransmitENTRoot'));
const TransmitHistoryRoot = lazy(() => import(/* webpackChunkName: "TransmitHistoryPage" */ '../pages/transmitHistory/TransmitHistoryRoot'));
const CannedReports = lazy(() => import(/* webpackChunkName: "CannedReportsPage" */ '../pages/cannedReports/CannedReports'));
const FileManagerPage = lazy(() => import(/* webpackChunkName: "FileManagerPage" */ '../pages/fileManager/FileManagerPageConnector'));
const SearchResultPage = lazy(() => import(/* webpackChunkName: "SearchResultPage" */ '../pages/search/searchResultPage/SearchResultPage'));
const SettingsPage = lazy(() => import(/* webpackChunkName: "SettingsPage" */ '../pages/settingsPage/SettingsPageConnector'));
const ManageUsersRoot = lazy(() => import(/* webpackChunkName: "ManageUserPage" */ '../pages/manageUsersPage/ManageUsersRoot'));
const NewTaxYear = lazy(() => import(/* webpackChunkName: "NewTaxYearPageConnector" */ '../pages/taxYearManagement/NewTaxYearPageConnector'));
const NotFoundPage = lazy(() => import(/* webpackChunkName: "NotFoundPage" */ '../pages/notFound/NotFoundPage'));
const CustomReportsRoot = lazy(() => import(/* webpackChunkName: "CustomReportsRoot" */ '../pages/customReports/CustomReportsRoot'));
const RealTimeTin = lazy(() => import(/* webpackChunkName: "RealTimeTinPage" */ '../pages/realTimeTin/RealTimeTinPage'));
const TinMatchingPage = lazy(() => import(/* webpackChunkName: "RealTimeTinPage" */ '../pages/tinMatching/TinMatchingPage'));
const WithholdingFormsRoot = lazy(() => import(/* webpackChunkName: "WithholdingFormsPage" */ '../pages/withholdingForms/WithholdingFormsRoot'));
const MigrateUserPage = lazy(() => import(/* webpackChunkName: "MigrateUserPage" */ '../pages/migrateUser/MigrateUserPage'));
const LoginSSO = lazy(() => import(/* webpackChunkName: "LoginSSO" */ '../pages/loginOtp/LoginSSO'));
const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ '../pages/logout/LogoutConnector'));
const Zendesk = lazy(() => import(/* webpackChunkName: "Zendesk" */ '../pages/zendesk/Zendesk'));
const NewPayerPage = lazy(() => import(/* webpackChunkName: "NewPayer" */ '../pages/newPayer/NewPayerPage'));
const DataEntry = lazy(() => import(/* webpackChunkName: "Zendesk" */ '../pages/dataEntry/DataEntryPageConnector'));
const ErrorPage = lazy(() => import(/* webpackChunkName: "ErrorPage" */ '../pages/error/ErrorPageConnector'));
const ImportCorpRoot = lazy(() => import(/* webpackChunkName: "ErrorPage" */ '../pages/corporate/import/ImportRoot'));

const NOT_FOUND_PATH = 'not-found';

const authRoutes = [
  {
    path: 'home',
    label: 'Home',
    component: HomePage,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRC],
    icon: Home
  },
  {
    path: 'overview',
    label: 'Overview',
    component: Overview,
    allPermissionsNeeded: [TIR_ENT, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRE],
    icon: Language
  },
  {
    label: 'Data',
    type: 'header'
  },
  {
    path: 'import/*',
    component: ImportCorpRoot,
    allPermissionsNeeded: [TIR_CORP, TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRC],
    internal: true
  },
  {
    path: 'payers',
    label: 'Payers',
    component: PayersPage,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYER],
    product: [productTypeCode.TIRC],
    icon: Store
  },
  {
    path: 'recipients',
    label: 'Recipients',
    component: RecipientsPage,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYEE],
    product: [productTypeCode.TIRC],
    icon: People
  },
  {
    path: 'forms',
    label: 'Forms',
    component: FormsPage,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRC],
    icon: FactCheck
  },
  {
    path: 'bulk-tin',
    label: 'Bulk TIN matching',
    component: BulkTin,
    allPermissionsNeeded: [TIR_CORP, TIR_R_BULK_TIN],
    someConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION, MANAGED_SERVICES, BULK_TIN],
    product: [productTypeCode.TIRC],
    icon: Person
  },
  {
    path: 'data/*',
    label: 'Data',
    component: DataEntRoot,
    allPermissionsNeeded: [TIR_ENT, TIR_R_RECORD_PAYER],
    product: [productTypeCode.TIRE],
    icon: FormatListBulleted,
    subLinks: [
      {
        label: 'Records',
        somePermissionsNeeded: TIR_R_RECORD_PAYER,
        product: [productTypeCode.TIRE],
        path: 'data/records'
      },
      {
        label: 'New Import',
        allPermissionsNeeded: [TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE],
        path: 'data/new-import'
      },
      {
        label: 'Import Tracker',
        path: 'data/import-tracker',
        allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      }
    ]
  },
  {
    path: 'errors',
    label: 'All Errors',
    component: ErrorPage,
    allPermissionsNeeded: [TIR_R_RECORD_PAYER],
    icon: FactCheck
  },
  {
    path: 'import-history/*',
    label: 'Import History',
    component: ImportHistory,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRC],
    icon: FactCheck
  },
  {
    path: 'tax-identity-management/*',
    label: 'Tax Identity Management',
    component: TaxIdentity,
    somePermissionsNeeded: [TIR_C_SOLICITATION, TIR_C_BULK_TIN],
    product: [productTypeCode.TIRE],
    someConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION, BULK_TIN],
    icon: Person,
    subLinks: [
      {
        label: 'Bulk TIN Matching',
        path: 'tax-identity-management/bulk-tin',
        allPermissionsNeeded: [TIR_R_BULK_TIN, TIR_C_SOLICITATION],
        someConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION, MANAGED_SERVICES, BULK_TIN],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'New Solicitation',
        path: 'tax-identity-management/new-solicitation',
        allPermissionsNeeded: [TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING, TIR_C_SOLICITATION],
        allConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Solicitation Tracker',
        path: 'tax-identity-management/solicitation-tracker',
        allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING, TIR_C_SOLICITATION],
        allConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Response Processing',
        path: 'tax-identity-management/response-processing',
        allPermissionsNeeded: [TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING, TIR_C_SOLICITATION],
        allConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION, TAX_IDENTITY_MANAGEMENT_RESPONSE],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Recipient Grouping',
        path: 'tax-identity-management/recipient-grouping',
        allPermissionsNeeded: [TIR_U_RECORD_PAYEE, TIR_C_RECORD_PAYEE],
        someConfigurationsNeeded: [TAX_IDENTITY_MANAGEMENT_SOLICITATION, BULK_TIN],
        product: [productTypeCode.TIRE]
      }
    ]
  },
  {
    label: 'Print & Transmit',
    type: 'header'
  },
  {
    path: 'import-results',
    label: 'Import Results',
    component: ImportResults,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYER],
    product: [productTypeCode.TIRC],
    internal: true
  },
  {
    path: 'print/*',
    label: 'Print',
    component: PrintCorpRoot,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRC],
    icon: LocalPrintshop
  },
  {
    path: 'print-ent/*',
    label: 'Print',
    allPermissionsNeeded: [TIR_ENT, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRE],
    component: PrintENTRoot,
    icon: LocalPrintshop,
    subLinks: [
      {
        label: 'New Print',
        path: 'print-ent',
        allPermissionsNeeded: [TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Print Tracker',
        path: 'print-ent/print-tracker',
        allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      }
    ]
  },
  {
    path: 'print-history/*',
    label: 'Print History',
    component: PrintHistoryRoot,
    allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    internal: true
  },
  {
    // Transmit for TIR-Corporate
    path: 'transmit/*',
    label: 'Transmit',
    component: TransmitCorpRoot,
    allPermissionsNeeded: [TIR_CORP, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRC],
    icon: Send
  },
  {
    // Transmit for TIR-Enterprise
    path: 'transmit-ent/*',
    label: 'Transmit',
    component: TransmitENTRoot,
    allPermissionsNeeded: [TIR_ENT, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRE],
    icon: Send,
    subLinks: [
      {
        label: 'New Transmittal',
        path: 'transmit-ent',
        allPermissionsNeeded: [TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Transmittal Tracker',
        path: 'transmit-ent/transmit-tracker',
        allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Reconciliation Tables',
        path: 'transmit-ent/reconciliation-tables',
        allPermissionsNeeded: [TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      },
      {
        label: 'Extension of Time',
        path: 'transmit-ent/extension-of-time',
        allPermissionsNeeded: [TIR_ENT, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
        product: [productTypeCode.TIRE]
      }
    ]
  },
  {
    path: 'withholding-forms/*',
    label: 'Withholding Forms',
    component: WithholdingFormsRoot,
    allPermissionsNeeded: [TIR_R_RECORD_WITHHOLDING],
    allConfigurationsNeeded: [WITHHOLDING_FORMS],
    icon: Domain
  },
  {
    path: 'transmit-history/:transmitType/*',
    label: 'Transmit History',
    component: TransmitHistoryRoot,
    allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    internal: true
  },
  {
    label: 'Utilities',
    type: 'header'
  },
  {
    path: 'tin-matching',
    label: 'TIN Matching',
    component: TinMatchingPage,
    someConfigurationsNeeded: [REAL_TIME_TIN, BULK_TIN, MANAGED_SERVICES],
    allPermissionsNeeded: [TIR_CORP, TIR_C_ID_VERIFY, TIR_R_VERIFY_IDENTITY],
    product: [productTypeCode.TIRC],
    icon: FormatListBulleted
  },
  {
    path: 'reports',
    label: 'Reports',
    component: CannedReports,
    allPermissionsNeeded: [TIR_C_REPORT, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    icon: BarChart
  },
  {
    path: 'custom-reports/*',
    label: 'Custom Reports',
    component: CustomReportsRoot,
    allPermissionsNeeded: [TIR_ENT, TIR_C_REPORT, TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    product: [productTypeCode.TIRE],
    icon: BarChart
  },
  {
    path: 'manage-files',
    label: 'File Manager',
    component: FileManagerPage,
    somePermissionsNeeded: [TIR_FM_ENTERDIR_PRINTFILES, TIR_FM_ENTERDIR_UPLOADED, TIR_FM_ENTERDIR_TIMIMAGES],
    icon: InsertDriveFile
  },
  {
    path: 'search-result',
    label: 'Search Result',
    component: SearchResultPage,
    allPermissionsNeeded: [TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING],
    internal: true
  },
  {
    path: 'settings',
    label: 'Settings',
    component: SettingsPage,
    somePermissionsNeeded: TIR_USER,
    internal: true
  },
  {
    path: 'manage-users/*',
    label: 'Manage User',
    component: ManageUsersRoot,
    allPermissionsNeeded: [S1_C_USER, S1_U_USER, S1_D_USER, S1_RESET_USER, TIR_C_USER, TIR_U_USER, TIR_D_USER, TIR_RESET_USER],
    internal: true
  },
  {
    path: 'new-tax-year',
    label: 'New Tax Year',
    component: NewTaxYear,
    allPermissionsNeeded: [TIR_MANAGE_TAX_YEARS, TIR_DEVELOPER],
    internal: true
  },
  {
    path: 'realtimetin',
    label: 'Data',
    component: RealTimeTin,
    somePermissionsNeeded: [TIR_RTT_USER],
    icon: FormatListBulleted
  },
  {
    path: 'new-payer',
    label: 'New Payer',
    component: NewPayerPage,
    product: [productTypeCode.TIRC],
    allPermissionsNeeded: [TIR_U_RECORD_PAYER],
    internal: true
  },
  {
    path: 'data-entry',
    component: DataEntry,
    internal: true,
    allPermissionsNeeded: [TIR_C_RECORD_PAYER]
  },
  {
    path: 'identity-management/*',
    internal: true,
    component: IdentityManagementRoot
  }
];

const handleHelpClick = () => {
  const productName = auth.getShortProductName().toLowerCase();
  setCookie(`token=${auth.getJwtToken()};expires=0;path=/help/${productName}/`);
};

const getBrowserRouter = () => createBrowserRouter([
  {
    path: '/signin-oidc',
    element: <LoginOidc />
  },
  {
    //Please kill this file after user migration, and sso users migrations, this is only for s1 old login.
    path: '/otp',
    element: <MigrateUserPage />
  },
  {
    path: '/sovos-sso-login',
    element: <LoginSSO />
  },
  {
    //Please kill this route and the component after sso clients are migrated to sovos identity
    path: '/sso-login',
    element: <LoginOtpConnector />
  },
  {
    //Please kill this route and the component after sso clients are migrated to sovos identity
    path: '/post-login',
    element: <PostLogin />
  },
  {
    path: '/internaladmin',
    element: <PostLogin />
  },
  {
    //Please kill this route and the component after sso clients are migrated to sovos identity
    path: '/sso-logout',
    element: <LogoutSSO />
  },
  {
    path: '/eula',
    element: <EulaPage />
  },
  {
    path: '/',
    element: <AuthRoute><App /></AuthRoute>,
    errorElement: <NotFoundPage helpUrl={ handleHelpClick } />,
    children: [{
      path: '',
      element: <Navigate to='/overview' />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/zendesk',
      element: <Zendesk />
    },
    ...authRoutes
      .filter(route => route.type !== 'header')
      .map(route => ({
        path: route.path,
        element: <ProtectedRoute route={ route }>
          <route.component />
        </ProtectedRoute>
      }))]
  }]
);

export {
  authRoutes,
  getBrowserRouter,
  NOT_FOUND_PATH,
  handleHelpClick
};
